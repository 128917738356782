import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  },
}));

function Homepage() {
  const classes = useStyles();

  return (
    <Grid className={classes.grid}>
      <a href="https://www.hapicoop.fr/nos-logements-1">
        Découvrir les logements inclusifs HAPI&apos;Coop
      </a>
    </Grid>
  );
}

export default Homepage;
